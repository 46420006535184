import * as React from 'react';
import { Link } from 'gatsby';
import { Container, Grid, Label, Message } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function UrgentComputing() {
  return (
    <>
      <H1>
        Urgent Computing: The largest Salvus simulation yet
      </H1>

      <CenteredImage
        caption={[
          "Snapshot of the wavefield simulated for the Mw 7 Aegan Sea Earthquake. ",
          <i>Satellite data from ArcGIS</i>
        ]}
      >
        <StaticImage
          src="./resources/mn_20Hz_snapshot.png"
          alt="Large-scale seismic simulations for urgent computing"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <p><i>
      When a devastating earthquake occurs...
      <ul>
      <li>... how accurately can we predict the ground motion?</li>
      <li>... how quickly can we assess its potential damages?</li>
      <li>... how reliably can we assist in hazard prevention and control?</li>
      </ul>
      </i></p>
      <p>
      In the future, large-scale seismic simulations for urgent computing
      might assist to answer some of these questions.
      The{' '}
      <a
        href="https://cheese-coe.eu"
        target="_blank"
      >
        ChEESE Center of Excellence in Solid Earth
      </a>{' '}
      aims to tackle these scientific, computational and socio-economical
      challenges on upcoming Exascale supercomputers.
      </p>
      <p>
      In this context, researchers from ETH Zurich and the Barcelona
      Supercomputing Center joined forces to simulate the Mw 7 earthquake that
      struck on October 30, 2020, with the epicenter about 14 km northeast of the Greek
      island of Samos in the Icarian Sea.
      </p>
      <p>
      And this turned into the largest Salvus simulation done so far...
      </p>
      <H2>Simulation setup</H2>
      <p>
        The question raised by the researchers was this: If we had access to an
        entire supercomputer, which frequencies can we resolve within a
        few hours of wall-time?
      </p>
      <p>
        After a succesful grant application, they indeed got access to the full
        BSC-CNS MareNostrum 4 supercomputer. The challenge was to run a simulation
        that produces seismograms accurate up to frequencies of 20 Hz including
        full-waveform physics and realistic 3D Earth structure.
      </p>
      <p>
        The scenario considers a domain covering an area of about 100 x 100 km at
        the surface including large parts of the Icarian Sea, the islands Chios and Samos,
        as well as Izmir and the Turkish coast line.
        The fault is modeled as a collection of moment-tensor point sources.
        The Earth model contains 3D Earth structure obtained from previous tomographies
        as well as realistic topography and bathymetry.
      </p>
      <H2>Facts and Figures</H2>
      <p>
        To keep the number of elements as small as possible, the researchers used
        spectral elements of order 7. In the absence of thin layers in the model,
        higher orders resolve the same frequencies with fewer elements per
        wavelength and usually outperform lower orders in terms of their{' '}
        <a
          href="https://mondaic.com/docs/knowledge_base/spectral_element_modelling/hp_refinement"
          target="_self"
        >
          cost/accuracy ratio
        </a>.
        Each 7-th order element has 512 local grid points. For a visco-elastic
        simulation, we need to store values for displacement, velocity, and acceleration,
        as well as the states of the ordinary differential equations that model attenuation.
        This gives a total of 14 fields at each grid point.
      </p>
      <p>
        The resulting spectral-element mesh contained almost 215 million elements, which
        gives more than 1.5 trillion unknowns -- and these are only the space-dependent
        degrees of freedom. The time integration additionally requires more than 100'000
        time steps. Multiplying the degrees of freedom in space and time gives a total
        of <b>164 664 116 147 707 904</b> unknows. That's a lot of number crunching!
      </p>
      <p>
        A preview of what has been computed after running for several hours on 84'000 MPI
        ranks is shown in the animation at the top of the page. The animiation depicts
        several snapshots of the magnitude of the displacement field
      </p>
      <p>
        At this extreme scale, a very important ingredient is to read and
        interpolate the spectral-element mesh in parallel. To this end, Salvus heavily
        relies on{' '}
        <a
          href="https://www.mcs.anl.gov/petsc/"
          target="_self"
        >
          PETSc
        </a>.
        DMPlex and details can be
        found in{' '}
        <a
          href="#references"
          target="_self"
        >
          this paper
        </a>.
      </p>
      <p>
        Interested in learning more? Futher information can be found in a recent presentation at{' '}
        <a
          href="#references"
          target="_self"
        >
          EGU 2021
        </a>.
      </p>

      <H2>Benefits of Salvus for ambient noise monitoring</H2>
      <p>
      <ul>
        <li>
        Excellent parallel performance and scalability on high-performance compute clusters.
        </li><li>
        Rapid prototyping for a fast transition from small-scale tests to full-production runs.
        </li><li>
        Parallel I/O and support for various output quantities.
        </li><li>
        Easy visualization of large output volumes.
        </li><li>
        Modern Python-based user interface and extendable research platform.
        </li>
      </ul>
      </p>
      <H2>References</H2>
      <p>
      <ul>
      <li>
        <b>M. Pienkowska et al. (2021):</b>{' '}
          <a
            href="https://doi.org/10.5194/egusphere-egu21-15516"
            target="_blank"
          >
            Deterministic modelling of seismic waves in the Urgent Computing context: progress towards a short-term assessment of seismic hazard
          </a>{' '}
          <i>EGU General Assembly 2021,</i> online, 19–30 Apr 2021, EGU21-15516. doi:{' '}
          <a
            href="https://doi.org/10.5194/egusphere-egu21-15516"
            target="_blank"
          >
            10.5194/egusphere-egu21-15516
          </a>{' '}
        </li>
        <li>
        <b>V. Hapla et al. (2021):</b>{' '}
          <a
            href="https://doi.org/10.1137/20M1332748"
            target="_blank"
          >
            Fully Parallel Mesh I/O Using PETSc DMPlex with an Application to Waveform Modeling.
          </a>{' '}
          <i>SIAM Journal on Scientific Computing,</i> 43:2, C127-C153 . doi:{' '}
          <a
            href="https://doi.org/10.1137/20M1332748"
            target="_blank"
          >
            10.1137/20M1332748
          </a>{' '}
        </li>
        </ul>
      </p>
      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to solve problems like
          the above
        </Grid.Row>
        <Grid.Row centered>
          ... and many more!
        </Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(UrgentComputing);
